import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import LoginForm from "../LoginForm";
import AuthStoreContext from "../../context/AuthStoreContext";
import useToastsStore from "../../hooks/useToastsStore";

const LoginPage: React.FC<{}> = () => {
  const auth = useContext(AuthStoreContext);
  const history = useHistory();
  const toast = useToastsStore();

  const [form, updateForm] = useState({
    username: "",
    password: "",
  });

  const onFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    updateForm({
      ...form,
      [name]: value,
    });
  };

  const onLogin = (e: React.SyntheticEvent): void => {
    e.preventDefault();

    auth.login(form)
      .then(({ redirectUrl }) => {
        history.push(redirectUrl ? redirectUrl : "/");
      })
      .catch(() => {
        toast.add({title: "Wrong credentials", type: "danger"});
      });
  };

  return (
    <LoginForm onFormChange={onFormChange} onLogin={onLogin} />
  );
};

export default LoginPage;
