import React from "react";
import { observer } from "mobx-react";
import useToastsStore from "../../hooks/useToastsStore";

import "./ToastsContainer.scss";
import Toast from "./Toast";

const ToastsContainer: React.FC<{}> = observer(() => {
  const toasts = useToastsStore();

  const deleteToast = (id: number): void => {
    toasts.deleteToast(id);
  };

  return (
    <div className="toasts-container">
      {toasts.data.map((toast, index) => {
        return <Toast key={index} toast={toast} onClose={deleteToast} />;
      })}
    </div>
  );
});

export default ToastsContainer;
