import React from "react";
import _ from "lodash";
import classnames from "classnames";

import "./Pagination.scss";

interface PaginationProps {
  current: number;
  pages: number;
  onPrev: () => void;
  onNext: () => void;
  onPage: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const paginationLimit = 6;
  const paginationStartLimit = 4;
  const paginationEndLimit = 3;

  const renderPageItems = (from: number, to: number): React.ReactNode => {
    return (
      _.range(from, to+1).map((item, index) => {
        return (
          <li key={index} className={classnames("page-item", {
            "active": item === props.current
          })}><span className="page-link" onClick={() => props.onPage(item)}>{item}</span></li>
        );
      })
    );
  };

  const renderStartPagination = (): React.ReactNode => {
    return (
      <React.Fragment>
        {renderPageItems(1, paginationStartLimit)}
        <li className="page-item disabled"><span className="page-link">...</span></li>
        <li className="page-item">
          <span className="page-link" onClick={() => props.onPage(props.pages)}>{props.pages}</span>
        </li>
      </React.Fragment>
    );
  };

  const renderEndPagination = (): React.ReactNode => {
    return (
      <React.Fragment>
        <li className="page-item"><span className="page-link" onClick={() => props.onPage(1)}>1</span></li>
        <li className="page-item disabled">
          <span className="page-link">...</span>
        </li>
        {renderPageItems(props.pages - paginationEndLimit, props.pages)}
      </React.Fragment>
    );
  };

  const renderMiddlePagination = (): React.ReactNode => {
    return (
      <React.Fragment>
        <li className="page-item"><span className="page-link" onClick={() => props.onPage(1)}>1</span></li>
        <li className="page-item disabled"><span className="page-link">...</span></li>
        {renderPageItems(props.current - 1, props.current+1)}
        <li className="page-item disabled"><span className="page-link">...</span></li>
        <li className="page-item">
          <span className="page-link" onClick={() => props.onPage(props.pages)}>{props.pages}</span>
        </li>
      </React.Fragment>
    );
  };

  const renderPaginationContent = (): React.ReactNode => {
    if(props.pages < paginationLimit) {
      return (
        renderPageItems(1, props.pages)
      );
    }

    if(props.current < paginationStartLimit) {
      return renderStartPagination();
    }

    if(props.current > props.pages - paginationEndLimit) {
      return renderEndPagination();
    }

    return renderMiddlePagination();

    
  };

  return (
    <nav>
      <ul className="pagination">
        <li className={classnames("page-item", {
          "disabled": props.current == 1
        })}>
          <span className="page-link" onClick={props.onPrev} aria-label="Previous">
            <span aria-hidden="true">«</span>
            <span className="sr-only">Previous</span>
          </span>
        </li>
        {renderPaginationContent()}
        <li className={classnames("page-item", {
          "disabled": props.current == props.pages
        })}>
          <span className="page-link" onClick={props.onNext} aria-label="Next">
            <span aria-hidden="true">»</span>
            <span className="sr-only">Next</span>
          </span>
        </li>
      </ul>
    </nav>
    
  );
};

export default Pagination;
