import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import stores from "../../stores";
import { useHistory } from "react-router-dom";
import Base from "../layout/Base";
import Container from "../layout/Container";
import Spinner from "../layout/Spinner";
import KeysTable from "../keys/KeysTable";
import { paths } from "../../constants/paths";
import Pagination from "../pagination/Pagination";

const KeysListPage: React.FC<{}> = observer(() => {
  const history = useHistory();
  const store = useMemo(() => stores.createAccessKeysStore(), []);
  const [showDisabled, setShowDisabled] = useState(false);
  const [search, setSearch] = useState("");
  const { page } = useParams();

  const { keys, pagination } = store;

  useEffect(() => {
    store.init(page, {
      isActive: !showDisabled,
      name: search
    });
  }, [store, page, showDisabled, search]);

  const onRowClick = (id: string): void => {
    history.push(paths.key.replace(":keyId", id));
  };

  const onPrevPage = (): void => {
    if(!page) {
      return;
    }
    history.push(`/keys/${parseInt(page)-1}`);
  };

  const onNextPage = (): void => {
    if(!page) {
      return;
    }
    history.push(`/keys/${parseInt(page)+1}`);
  };

  const onSetPage = (page: number): void => {
    history.push(`/keys/${page}`);
  };

  const renderPagination = (): React.ReactNode => {
    if(!pagination) {
      return null;
    }

    return (
      <Pagination
        current={parseInt(pagination?.currentPage)}
        pages={parseInt(pagination?.totalPages)}
        onPrev={onPrevPage} onNext={onNextPage}
        onPage={onSetPage}
      />
    );
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
    onSetPage(1); //return to page 1 on filter change
  };

  const toggleCheckbox = (): void => {
    setShowDisabled((showDisabled) => !showDisabled);
    onSetPage(1); //return to page 1 on filter change
  };

  const renderTableConditionally = (): React.ReactNode => {
    if (keys && keys.length !== 0) {
      return (
        <KeysTable
          data={keys}
          onRowClick={onRowClick}
        />
      );
    }

    return <div className="text-center">There are no keys matching filters.</div>;
  };

  const renderContent = (): React.ReactNode => {
    if (!keys) {
      return <Spinner />;
    }

    return (
      <div className="col-xl-8 col-lg-12 mb-4 list-channel-page">
        <div className="card">
          <div className="card-header">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-6">
                Keys list
              </div>
              <div className="col-6 text-right">
                <button
                  className="btn btn-primary"
                  onClick={() => history.push(paths.keyAdd)}
                >
                  Add new
                </button>
              </div>
            </div>
            <div className="row d-flex align-items-center justify-content-between text-left">
              <div className="col-md-6 input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="cil-search"></i>
                  </span>
                </div>
                <input
                    className="form-control"
                    type="text"
                    name="input1-group1"
                    placeholder="Type to search by key name"
                    value={search}
                    onChange={onSearchChange}
                />
              </div>
              <div className="col-md-6 input-group pt-2">
                <div className="form-check form-check-inline mr-1">
                  <input
                    className="form-check-input"
                    type="checkbox" id="showDisabledKeys"
                    name="showDisabledKeys"
                    checked={showDisabled}
                    onChange={toggleCheckbox}
                  />
                  <label className="form-check-label" htmlFor="showDisabledKeys">include disabled keys</label>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            {renderTableConditionally()}
            {renderPagination()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Base>
      <Container center>{renderContent()}</Container>
    </Base>
  );
});

export default KeysListPage;
