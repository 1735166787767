import React from "react";
import usePopupStore from "../../hooks/usePopupStore";
import classnames from "classnames";

interface PrompPopupProps {
  headerText: string;
  bodyText: string;
  type?: "danger" | "warning" | "info" | "success" | "regular";
  onAccept?: () => void;
}

const PromptPopup: React.FC<PrompPopupProps> = ({
  onAccept,
  headerText,
  bodyText,
  type
}) => {
  const popupStore = usePopupStore();

  const onProceed = (): void => {
    popupStore.hide();
    onAccept && onAccept();
  };

  return (
    <div className={classnames("modal-dialog", { [`modal-${type}`]: type })}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{headerText}</h5>
          <button
            type="button"
            className="close"
            onClick={() => popupStore.hide()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{bodyText}</p>
        </div>
        <div className="modal-footer">
          {onAccept && (
            <button
              type="button"
              className={classnames("btn btn-primary", {[`btn-${type}`]: type})}
              onClick={onProceed}
            >
              Proceed
            </button>
          )}
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => popupStore.hide()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromptPopup;
