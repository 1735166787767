import appConfig from "appConfig";

import PopupStore from "./PopupStore";
import ToastsStore from "./ToastsStore";
import AuthStore from "./AuthStore";
import AuthApiService from "../services/AuthApiService";

import HttpConnector from "../services/HttpConnectorService";
import AccessKeysStore from "./AccessKeysStore";
import AccessKeyStore from "./AccessKeyStore";
import AccessKeysApiService from "../services/AccessKeysApiService";

const authApiService = new AuthApiService({
  prefixUrl: appConfig.API_URL,
});
const authStore = new AuthStore(authApiService);

const http = new HttpConnector({
  prefixUrl: appConfig.API_URL,
  retry: {
    statusCodes: [401],
    afterStatusCodes: [401],
    limit: 2,
    methods: ["post", "get", "put", "delete", "patch"],
  }
}, authApiService, authStore);

const toastStore = new ToastsStore();

export default {
  popup: new PopupStore(),
  toasts: toastStore,
  auth: authStore,

  createAccessKeysStore: () => new AccessKeysStore(new AccessKeysApiService(http), toastStore),
  createAccessKeyStore: () => new AccessKeyStore(new AccessKeysApiService(http), toastStore),
};
