import React from "react";
import { action, observable } from "mobx";

import POPUPS_TYPES from "../components/popups/PopupsTypes";
import POPUPS_CONFIG from "../components/popups/PopupsConfig";


type PopupType = typeof POPUPS_TYPES[keyof typeof POPUPS_TYPES];
type PopupProps<T extends PopupType> = React.ComponentProps<typeof POPUPS_CONFIG[T]>;

export default class PopupStore {
  @observable type?: PopupType;
  @observable isVisible = false;

  @observable params = {};

  @action show<T extends PopupType>(type: T, params: PopupProps<T>): void {
    this.type = type;
    this.isVisible = true;
    this.params = params;
  }

  @action hide(): void {
    this.type = undefined;
    this.isVisible = false;
  }

  @action showPromptPopup({ headerText, bodyText, type, onAccept }: PopupProps<typeof POPUPS_TYPES.PROMPT>): void {
    this.show(POPUPS_TYPES.PROMPT, { headerText, bodyText, type, onAccept });
  }
}
