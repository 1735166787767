import { action, observable } from "mobx";
import AccessKeysApiService from "../services/AccessKeysApiService";
import ToastsStore from "./ToastsStore";
import { Keys } from "../interfaces/Keys";
import { KeyFormInterface } from "../interfaces/KeyFormInterface";
import { PaginationInterface } from "../interfaces/Pagination";
import { KeysQuery } from "../interfaces/Keys";

export default class AccessKeysStore {

  constructor(
    public AccessKeysApi: AccessKeysApiService,
    public toastsStore: ToastsStore,
  ) {}

  @observable keys: Keys[] | null = null;
  @observable pagination: PaginationInterface | null = null;
  @observable isInitialized = false;
  @observable isError = false;
  @observable isFinished = true;
  @observable addedKey: Keys | null = null;

  @action async init(page?: string, query?: KeysQuery): Promise<void> {
    this.getKeys(page ? page : "1", query);
  }

  @action async getKeys(page: string, query?: KeysQuery): Promise<void> {
    try {
      const response = await this.AccessKeysApi.getKeys(page, query);
      this.keys = response.body as Keys[];
      this.pagination = response.responseHeaders as PaginationInterface;
    }
    catch(e) {
      this.isError = true;
    }
    finally {
      this.isInitialized = true;
    }
  }

  @action async getKey(id: string): Promise<Keys> {
    return await this.AccessKeysApi.getKey(id);
  }

  @action async addKey(form: KeyFormInterface): Promise<void> {
    const { patientQuantity } = form;

    if(form.name === "" || !(patientQuantity >= 0 && patientQuantity <= 10000)) {
      this.toastsStore.add({
        title: "Error. Incorrect values.",
        type: "danger"
      });
      return;
    }

    this.isFinished = false;

    try {
      const key =  await this.AccessKeysApi.addKey(form);
      this.addedKey = key;
    }
    catch(e) {
      this.toastsStore.add({
        title: "Error",
        type: "danger"
      });
    }
    finally {
      this.isFinished = true;
    }
  }
}
