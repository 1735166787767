import ky, { Input, Options, ResponsePromise } from "ky";
import AuthApiService from "./AuthApiService";
import { STORE_TOKEN_KEY, REFRESH_TOKEN_KEY } from "./AuthApiService";
import AuthStore from "../stores/AuthStore";

class HttpConnectorService {
  instance: typeof ky;
  headers: {} = {};

  constructor(options: Options, private authService: AuthApiService, private authStore: AuthStore) {
    if(options.headers) {
      this.headers = options.headers;
    }

    this.instance = ky.create(options);
    this.setHooks();
  }

  get(url: Input, options?: Options): ResponsePromise {
    return this.instance.get(url, options);
  }
  post(url: Input, options?: Options): ResponsePromise {
    return this.instance.post(url, options);
  }
  put(url: Input, options?: Options): ResponsePromise {
    return this.instance.put(url, options);
  }
  delete(url: Input, options?: Options): ResponsePromise {
    return this.instance.delete(url, options);
  }
  patch(url: Input, options?: Options): ResponsePromise {
    return this.instance.patch(url, options);
  }
  head(url: Input, options?: Options): ResponsePromise {
    return this.instance.head(url, options);
  }

  extend(options: Options): void {
    this.instance = this.instance.extend(options);
  }

  addHeader(name: string, value: string): void {
    this.headers = {
      ...this.headers,
      [name]: value,
    };

    this.extend({
      headers: this.headers
    });
  }

  removeHeader(name: string): void {
    this.headers = {
      ...this.headers,
      [name]: undefined,
    };

    this.extend({
      headers: this.headers,
    });
  }

  private setHooks(): void {
    this.extend({
      hooks: {
        beforeRequest: [
          (request) => {
            const token = sessionStorage.getItem(STORE_TOKEN_KEY);
            if(!token) {
              request;
            }
            request.headers.set("Authorization", `Bearer ${token}`);
          }
        ],
        beforeRetry: [
           async (options) => {
            const body =  await options.response.json();
            if(options.response.status === 401 && body.error === "invalid_grant") {
              const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);

              if(!refreshToken) {
                this.authStore.logout();
              } 
              try {
                await this.authService.refreshRequest();
              }
              catch(error) {
                this.authStore.logout();
              }
            }
          }
        ]
      }
    });
  }

}

export default HttpConnectorService;
