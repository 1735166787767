import { action, observable } from "mobx";
import AccessKeysApiService from "../services/AccessKeysApiService";
import ToastsStore from "./ToastsStore";
import { Keys } from "../interfaces/Keys";
import { KeyFormInterface } from "../interfaces/KeyFormInterface";

export default class AccessKeysStore {

  constructor(
    public AccessKeysApi: AccessKeysApiService,
    public toastsStore: ToastsStore,
  ) {}

  @observable key: Keys | null = null;
  @observable isInitialized = false;
  @observable isDeactivatedFinish = false;
  @observable isError = false;

  @action async init(id: string): Promise<void> {
    try {
      this.key = await this.AccessKeysApi.getKey(id);
    }
    catch(e) {
      this.isError = true;
    }
    finally {
      this.isInitialized = true;
    }
  }

  @action async getKey(id: string): Promise<Keys> {
    return await this.AccessKeysApi.getKey(id);
  }

  @action async editKey(form: KeyFormInterface, id: string): Promise<void> {
    try {
      await this.AccessKeysApi.editKey(form, id);

      this.toastsStore.add({
        title: "Changed successfully",
        type: "success"
      });
    }
    catch(e) {
      this.toastsStore.add({
        title: "Error",
        type: "danger"
      });
    }
  }
  
  @action async deactivateKey(id: string): Promise<void> {
    try {
      this.key = await this.AccessKeysApi.disableKey(id);

      this.toastsStore.add({
        title: "Successfully deactivated key",
        type: "success"
      });

      this.isDeactivatedFinish = true;

    }
    catch(e) {
      this.toastsStore.add({
        title: "Error",
        type: "danger"
      });
    }
  }

  @action async activateKey(id: string): Promise<void> {
    try {
      this.key = await this.AccessKeysApi.enableKey(id);

      this.toastsStore.add({
        title: "Successfully activated key",
        type: "success"
      });
    }
    catch(e) {
      this.toastsStore.add({
        title: "Error",
        type: "danger"
      });
    }
  }
}
