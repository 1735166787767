import ky from "ky";
import HttpConnectorService from "./HttpConnectorService";
import { ApiHttpError, ApiHttpResponse } from "../interfaces/AbstractHttpService";

abstract class AbstractHttpService {
  constructor(public http: HttpConnectorService){}

  resolve = async (response: Response): Promise<ApiHttpResponse> => {
    const lastPage = response.headers.get("x-last-page");
    const currentPage = response.headers.get("x-page");
    const perPage = response.headers.get("x-per-page");
    const totalPages = response.headers.get("x-last-page");

    return {
      body: await response.text().then(text => (text ? JSON.parse(text) : {})),
      responseHeaders: {
        lastPage,
        currentPage,
        perPage,
        totalPages
      }
    };
  };

  reject = (error: ky.HTTPError): Promise<never> => {
    // `error.response` will be undefined if server don't response
    // See: https://github.com/sindresorhus/ky/issues/107#issuecomment-471112770
    if(!error.response){
      if( process.env.NODE_ENV === "development") {
        // eslint-disable-next-line
        console.error(error);
      }

      return Promise.reject({
        statusCode: 0,
        error: "Communicate error",
        message: "Can`t communicate with server",
      });
    }

    // `error.response` is instance of Response class
    // See: https://github.com/sindresorhus/ky/issues/107#issuecomment-476048453
    return error.response.json()
      .then((err: ApiHttpError) => Promise.reject(err));
  };

}

export default AbstractHttpService;
