import { action, observable } from "mobx";
import { Notification } from "../interfaces/Notification";

export default class ToastsStore {
  @observable data: Notification[] = [];

  @action deleteToast(id: number): void {
    this.data = this.data.filter(item => item.id !== id);
  }

  @action add(data: Notification): void {
    const timeout = data.timeout || 5000;
    const id = new Date().getTime();

    this.data.push({
      timeout,
      id,
      ...data,
    });

    setTimeout(() => {
      this.data = this.data.filter(item => item.id !== id);
    }, timeout);
  }
}
