import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import stores from "../../stores";
import Base from "../layout/Base";
import Container from "../layout/Container";
import useToastsStore from "../../hooks/useToastsStore";
import usePopupStore from "../../hooks/usePopupStore";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Spinner from "../layout/Spinner";

const KeyPage: React.FC<{}> = observer(() => {
  const store = useMemo(() => stores.createAccessKeyStore(), []);
  const history = useHistory();
  const [form, setForm] = useState({
    name: "",
    patientQuantity: "0"
  });

  const { keyId } = useParams();
  const toast = useToastsStore();
  const popup = usePopupStore();

  useEffect(() => {
    keyId && store.init(keyId);
  }, [store, keyId]);

  useEffect(() => {
    if (store.isDeactivatedFinish) {
      history.push("/keys/1");
    }
  }, [store.isDeactivatedFinish, history]);

  useEffect(() => {
   setForm({
    name: store.key?.name ?? "",
    patientQuantity: store.key?.patientQuantity.toString() ?? "0"
   });
  }, [store.isInitialized, store.key]);

  if(store.isError) {
    return (
      <Base>
        <Container>
          This key is not exist
        </Container>
      </Base>
    );
  }

  const isActive = store.key?.isActive;

  const onFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  };

  const onSave = (): void => {
    if(!keyId) {
      return toast.add({
        title: "keyId not found",
        type: "danger"
      });
    }

    store.editKey({
      ...form,
      patientQuantity: parseInt(form.patientQuantity),
    }, keyId);
  };

  const onDeactivate = (): void => {
    if(!keyId) {
      return toast.add({
        title: "keyId not found",
        type: "danger"
      });
    }

    store.deactivateKey(keyId);
  };

  const onActivate = (): void => {
    if(!keyId) {
      return toast.add({
        title: "keyId not found",
        type: "danger"
      });
    }

    store.activateKey(keyId);
  };

  const renderActivateButton = (): React.ReactNode => {
    if(isActive) {
      return (
        <button
          className="btn btn-outline-danger"
          onClick={() =>
            popup.showPromptPopup({
              type:"danger",
              headerText: "Deactivate key",
              bodyText: "Are you sure?",
              onAccept: () => onDeactivate(),
            })
          }
        >
          Deactivate key
        </button>
      );
    }

    return (
      <button
        className="btn btn-outline-success"
        onClick={() =>
          popup.showPromptPopup({
            type:"danger",
            headerText: "Activate key",
            bodyText: "Are you sure?",
            onAccept: () => onActivate(),
          })
        }
      >
        Activate key
      </button>
    );
  };

  const renderBadge = (): React.ReactNode => {
    if(store.key?.isActive) {
      return (
        <span className="badge badge-success">active</span>
      );
    }

    return (
      <span className={"badge badge-danger"}>disabled</span>
    );
  };

  const renderContent = (): React.ReactNode => {
    if(!store.key) {
      return <Spinner />;
    }

    return (
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-lg-6">
              Key details
            </div>
            <div className="col-lg-6 text-right">
              {renderActivateButton()}
            </div>
          </div>
        </div>
        <div className="card-body">
          <ul className="list-group">
            <li className="list-group-item">
              <div className="row">
                <div className="col-md-6 font-weight-bold">Key:</div>
                <div className="col-md-6"><var>{store.key?.key}</var></div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <div className="col-md-6 font-weight-bold">Created at:</div>
                <div className="col-md-6">{moment(store.key?.createdAt).format("DD.MM.YYYY HH:mm:ss")}</div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <div className="col-md-6 font-weight-bold">Status:</div>
                <div className="col-md-6">{renderBadge()}</div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <div className="col-md-6 font-weight-bold">Name:</div>
                <div className="col-md-6">
                  <input
                    name="name"
                    disabled={!isActive}
                    value={form.name}
                    onChange={onFormChange}
                    className="form-control"
                    placeholder="Enter key name"
                  />
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <div className="col-md-6 font-weight-bold">Limit:</div>
                <div className="col-md-6">
                  <input
                    name="patientQuantity"
                    type="number"
                    min="0"
                    max="10000"
                    disabled={!isActive}
                    value={form.patientQuantity}
                    onChange={onFormChange}
                    className="form-control"
                    placeholder="Enter limit"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="card-footer">
          <button
            onClick={onSave}
            className="btn btn-primary"
            disabled={!isActive}
          >
            Update
          </button>
        </div>
      </div>
    );
  };

  return (
    <Base>
      <Container center>
        <div className="col-xl-8 col-lg-12 mb-4">
          {renderContent()}
        </div>
      </Container>
    </Base>
  );
});

export default KeyPage;
