import { hot } from "react-hot-loader/root";
import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import routes from "../constants/routes";
import { RouteProps } from "react-router-dom";
import PopupsContainer from "./popups/PopupsContainer";
import ToastsContainer from "./toasts/ToastsContainer";
import AuthProvider from "./AuthProvider";
import RouteAuth from "./RouteAuth";

const App: React.FC<{}> = () => {
  return (
    <AuthProvider>
      <Router>
        <ToastsContainer />
        <PopupsContainer />
        <Switch>
        <Redirect exact from="/" to="/keys/1" />
          {routes.map((props: RouteProps) => (
            <RouteAuth key={props.path as string} {...props} />
          ))}
        </Switch>
      </Router>
    </AuthProvider>
  );
};

export default hot(App);
