import { RouteProps } from "react-router-dom";
import LoginPage from "../components/pages/LoginPage";
import KeysListPage from "../components/pages/KeysListPage";
import KeyPage from "../components/pages/KeyPage";
import AddKeyPage from "../components/pages/AddKeyPage";
import { paths } from "../constants/paths";

export enum SCOPES {
  UNAUTHORIZED = "unauthorized",
  AUTHORIZED = "authorized",
}

export interface RouteInterface extends RouteProps {
  scope?: SCOPES;
}

const routes: RouteInterface[] = [
  {
    path: paths.login,
    component: LoginPage,
    scope: SCOPES.UNAUTHORIZED,
  },
  {
    path: paths.keyAdd,
    component: AddKeyPage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.key,
    component: KeyPage,
    scope: SCOPES.AUTHORIZED,
  },
  {
    path: paths.keyList,
    component: KeysListPage,
    scope: SCOPES.AUTHORIZED,
  }
];

export default routes;
