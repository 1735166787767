import React from "react";

interface LoadingButtonProps {
  className: string;
  onClick: Function;
  isLoading: boolean;
  children: React.ReactNode;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  className,
  onClick,
  isLoading,
  children
}) => {
  if (isLoading) {
    return (
      <button className={className} type="button" disabled>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    );
  }

  return (
    <button className={className} onClick={() => onClick()}>
      {children}
    </button>
  );
};

export default LoadingButton;
