// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js!normalize.css/normalize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js!@coreui/coreui-pro/dist/css/coreui.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../node_modules/css-loader/dist/cjs.js!@coreui/icons/css/all.min.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
exports.push([module.id, "body{width:100%;margin:0;padding:0}\n", ""]);
// Exports
module.exports = exports;
