import React, { useState, useLayoutEffect } from "react";

import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { SidebarTab } from "../../interfaces/SidebarTab";
import { sidebarTabs } from "../../constants/sideBarTabs";

interface SideBarProps {
  isOpen: boolean;
}

const SideBar: React.FC<SideBarProps> = ({ isOpen }) => {
  const [focusedTab, focusTab] = useState("");

  const getActiveTabfromPath = (path: string): string => {
    const pathNameTab = path.split("/");
    return pathNameTab[1];
  };

  useLayoutEffect(() => {
    focusTab(getActiveTabfromPath(window.location.pathname));
  }, []);

  const renderTab = (tab: SidebarTab, index: number): React.ReactNode => {
    if (tab.tabs) {
      return (
        <li
          key={index}
          className={classnames(
            "c-sidebar-lg-show c-sidebar-nav-item c-sidebar-nav-dropdown",
            {
              ["c-show"]: focusedTab === getActiveTabfromPath(tab.path)
            }
          )}
        >
          <a
            onClick={() =>
              focusTab(
                focusedTab === getActiveTabfromPath(tab.path)
                  ? ""
                  : getActiveTabfromPath(tab.path)
              )
            }
            className="c-sidebar-nav-link c-sidebar-nav-dropdown-toggle"
          >
            <i className={`${tab.ico} c-sidebar-nav-icon`}></i> {tab.name}
          </a>
          <ul className="c-sidebar-nav-dropdown-items">
            {tab.tabs.map((tab, index) => renderTab(tab, index))}
          </ul>
        </li>
      );
    }

    return (
      <li className="c-sidebar-nav-item" key={index}>
        <NavLink
          className={classnames("c-sidebar-nav-link", {
            ["c-active"]: window.location.pathname === tab.path
          })}
          to={tab.path}
        >
          <span className="c-sidebar-nav-icon"></span> {tab.name}
        </NavLink>
      </li>
    );
  };

  return (
    <React.Fragment>
      <div
        className={classnames("c-sidebar c-sidebar-fixed", {
          ["c-sidebar-show"]: isOpen
        })}
        id="sidebar"
      >
        <div className="c-sidebar-brand d-lg-down-none">
          <span className="ml-3">ABAStroke</span>
        </div>
        <ul className="c-sidebar-nav">
          {sidebarTabs.map((tab, index) => renderTab(tab, index))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default SideBar;
