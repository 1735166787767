import React, { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react";
import stores from "../../stores";
import Base from "../layout/Base";
import Container from "../layout/Container";
import LoadingButton from "../layout/LoadingButton";
import { useHistory } from "react-router-dom";

const AddKeyPage: React.FC<{}> = observer(() => {
  const [form, setForm] = useState({ name: "", patientQuantity: "0" });
  const history = useHistory();

  const store = useMemo(() => stores.createAccessKeysStore(), []);

  useEffect(() => {
    if (store.addedKey) {
      history.push(`/key/${store.addedKey.id}`);
    }
  }, [store.addedKey, history]);

  const onFormChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  };

  const onSubmit = (): void => {
    store.addKey({
      ...form,
      patientQuantity: parseInt(form.patientQuantity),
    });
  };

  return (
    <Base>
      <Container>
        <div className="col-xl-4 col-md-6 col-sm-12 mb-4 add-channel-page">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>Key name</label>
                <input
                  name="name"
                  defaultValue={form ? form.name : ""}
                  onChange={onFormChange}
                  className="form-control"
                  placeholder="Enter key name"
                />
              </div>
              <div className="form-group">
                <label>Limit of patient</label>
                <input
                  name="patientQuantity"
                  type="number"
                  min="0"
                  max="10000"
                  defaultValue={form ? form.patientQuantity : 0}
                  onChange={onFormChange}
                  className="form-control"
                  placeholder="Enter limit"
                />
              </div>
            </div>
            <div className="card-footer">
              <LoadingButton
                onClick={onSubmit}
                className="btn btn-primary"
                isLoading={!store.isFinished}
              >
                Add
              </LoadingButton>
            </div>
          </div>
        </div>
      </Container>
    </Base>
  );
});

export default AddKeyPage;
