import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./components/App";

import "./assets/styles/index.scss";

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
