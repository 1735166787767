import React from "react";
import classnames from "classnames";

interface ContainerProps {
  children: React.ReactNode;
  center?: boolean;
}

const Container: React.FC<ContainerProps> = ({ children, center }) => {
  return (
    <div
      className={classnames("container-fluid", {
        ["d-flex justify-content-center"]: center
      })}
    >
      {children}
    </div>
  );
};

export default Container;
