import React, { useState, useLayoutEffect } from "react";

import SideBar from "./SideBar";
import TopBar from "./TopBar";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const HIDE_SIDEBAR_WIDTH = 991;

interface BaseProps {
  children: React.ReactNode;
}

const Base: React.FC<BaseProps> = (props: BaseProps) => {
  const [isSidebarOpen, setSidebar] = useState(true);
  const [hiddenByUser, setHiddenByUser] = useState(false);
  const { width } = useWindowDimensions();
  const sidebaShouldBeMinimalized = width < HIDE_SIDEBAR_WIDTH;

  const toggleSidebar = (): void => {
    setSidebar(!isSidebarOpen);

    if (!sidebaShouldBeMinimalized && isSidebarOpen) setHiddenByUser(true);
  };

  useLayoutEffect(() => {
    if (sidebaShouldBeMinimalized) {
      setHiddenByUser(false);
      return setSidebar(false);
    }

    if (!hiddenByUser) {
      return setSidebar(true);
    }
  }, [hiddenByUser, sidebaShouldBeMinimalized, width]);

  return (
    <div className="base">
      <SideBar isOpen={isSidebarOpen} />
      <div className="c-wrapper ">
        <TopBar toggleSidebar={toggleSidebar} />
        <main
          onClick={() => sidebaShouldBeMinimalized && setSidebar(false)}
          className="c-main"
        >
          {props.children}
        </main>
      </div>
    </div>
  );
};

export default Base;
