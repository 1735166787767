import { action, observable } from "mobx";

import AuthApiService from "../services/AuthApiService";
import * as AuthApiServiceInterface from "../interfaces/AuthApiService";

type RedirectUrl = string | null;

export default class AuthStore {
  constructor(public api: AuthApiService) {}

  @observable isLogged = false;
  @observable isInitialized = false;

  @action init(): void {
    if (this.api.init()) {
      this.isLogged = true;
    }
    this.isInitialized = true;
  }

  @action login(
    data: AuthApiServiceInterface.LoginParamData
  ): Promise<{
    data: string;
    redirectUrl: RedirectUrl;
  }> {
    return this.api.login(data).then(res => {
      this.isLogged = true;

      return {
        data: res,
        redirectUrl: "/keys/1",
      };
    });
  }

  @action logout(): void {
    this.api.logout();
    this.isLogged = false;
  }
}
