import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import AuthStoreContext from "../context/AuthStoreContext";

interface PropsInterface {
  children: JSX.Element;
}

const AuthProvider: React.FC<PropsInterface> = observer(({ children }) => {
  const auth = useContext(AuthStoreContext);

  useEffect(() => {
    auth.init();
  }, [auth]);

  if(!auth.isInitialized) {
    return null;
  }

  return children;
});

export default AuthProvider;
