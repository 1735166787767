import React from "react";
import classnames from "classnames";

interface SpinnerProps {
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return (
    <div className={classnames("spinner-grow", className)} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export default Spinner;
