import { paths } from "./paths";

export const sidebarTabs = [
  {
    name: "Keys",
    path: "/keys",
    ico: "cil-user",
    tabs: [
      {
        name: "Key list",
        path: "/keys/1"
      },
      {
        name: "Add key",
        path: paths.keyAdd
      },
    ]
  },
];
