import React from "react";

interface LoginFormProps {
  onFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLogin: (e: React.SyntheticEvent) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onFormChange, onLogin }) => {
  return (
    <div className="flex-row align-items-center c-wrapper">
      <div className="container">
        <div className="row justify-content-center align-self-center">
          <div className="col-md-8">
            <div className="card-group">
              <div className="card p-4">
                <div className="card-body">
                  <h1>Login</h1>
                  <p className="text-muted">Sign In to your account</p>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text"></span>
                    </div>
                    <input
                      name="username"
                      onChange={onFormChange}
                      className="form-control"
                      type="text"
                      placeholder="Username"
                    />
                  </div>
                  <div className="input-group mb-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text"></span>
                    </div>
                    <input
                      name="password"
                      onChange={onFormChange}
                      className="form-control"
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button className="btn btn-primary px-4" type="button" onClick={onLogin}>
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card text-white bg-primary py-5 d-md-down-none">
                <div className="card-body text-center">
                  <div>
                    <h2>ABAStroke</h2>
                    <p>back office</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
