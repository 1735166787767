import AbstractHttpService from "./AbstractHttpService";
import { Keys, KeysQuery } from "../interfaces/Keys";
import { Keys as ApiKeys } from "../interfaces/adapters/Keys";
import { KeyFormInterface } from "../interfaces/KeyFormInterface";
import { ApiHttpResponse } from "../interfaces/AbstractHttpService";

class AccessKeysApiService extends AbstractHttpService {
  private port(keys: ApiKeys): Keys {
    return {
      patientQuantity: keys.patientQuantity,
      name: keys.name,
      key: keys.key,
      createdAt: keys.createdAt,
      id: keys.id,
      isActive: keys.isActive
    };
  }

  private portResponse = (response: ApiHttpResponse): ApiKeys => {
    return this.port(response.body as ApiKeys);
  };

  private portResponseItems = (response: ApiHttpResponse): ApiHttpResponse => {
    return {
      body: (response.body as Keys[]).map(item => this.port(item)),
      responseHeaders: response.responseHeaders
    };
  };

  addKey(form: KeyFormInterface): Promise<Keys> {
    return this.http
      .post(`api/admin/v1/keys`, { body: JSON.stringify(form) })
      .then(this.resolve)
      .then(this.portResponse)
      .catch(this.reject);
  }

  getKeys(page: string, queryParams?: KeysQuery): Promise<ApiHttpResponse> {

    const query = new URLSearchParams({
      page: page
    });

    if(queryParams?.isActive) {
      query.append("is_active", queryParams.isActive.toString());
    }

    if(queryParams?.name) {
      query.append("name", queryParams.name.toString());
    }

    return this.http
      .get(`api/admin/v1/keys?${query.toString()}`)
      .then(this.resolve)
      .then(this.portResponseItems)
      .catch(this.reject);
  }

  getKey(id: string): Promise<Keys> {
    return this.http
      .get(`api/admin/v1/keys/${id}`)
      .then(this.resolve)
      .then(this.portResponse)
      .catch(this.reject);
  }

  editKey(form: KeyFormInterface, id: string): Promise<Keys> {
    return this.http
      .patch(`api/admin/v1/keys/${id}`, { body: JSON.stringify(form) })
      .then(this.resolve)
      .then(this.portResponse)
      .catch(this.reject);
  }

  disableKey(id: string): Promise<Keys> {
    return this.http
      .put(`api/admin/v1/disabled_keys/${id}`)
      .then(this.resolve)
      .then(this.portResponse)
      .catch(this.reject);
  }

  enableKey(id: string): Promise<Keys> {
    return this.http
      .put(`api/admin/v1/active_keys/${id}`)
      .then(this.resolve)
      .then(this.portResponse)
      .catch(this.reject);
  }

}

export default AccessKeysApiService;
