import React from "react";
import moment from "moment";

import { Keys } from "../../interfaces/Keys";

import "./KeysTable.scss";

interface KeyTableProps {
  data: Keys[];
  onRowClick?: (id: string) => void;
}

interface KeyTableColumn {
  displayName: string;
  type: string;
}

const KeyTableColumns: KeyTableColumn[] = [
  { displayName: "Key", type: "key" },
  { displayName: "Key name", type: "keyName" },
  { displayName: "Limit", type: "limit" },
  { displayName: "Created at", type: "createdAt" }
];


const KeyTable: React.FC<KeyTableProps> = (props: KeyTableProps) => {

  const renderHeaderCell = (
    column: KeyTableColumn,
    index: number
  ): React.ReactNode => {
    return (
      <th key={index}>
        {column.displayName}
      </th>
    );
  };

  const handleRowClick = (id: string): void => {
    if(!props.onRowClick) {
      return;
    }
    props.onRowClick(id);
  };

  const renderData = (): React.ReactNode => {
    return props.data.map((item: Keys, index) => {
      return (
        <tr key={index} onClick={() => handleRowClick(item.id)}>
          <td>
            <var>{item.key}</var> { !item.isActive && <span className="badge badge-danger">disabled</span> }
          </td>
          <td>
            {item.name}
          </td>
          <td>
            {item.patientQuantity}
          </td>
          <td>{moment(item.createdAt).format("DD.MM.YYYY")}</td>
        </tr>
      );
    });
  };

  return (
    <div>
      <table className="table table-hover table-bordered table-striped table-sm keys-table">
        <thead>
          <tr>
            {KeyTableColumns.map((column, index) =>
              renderHeaderCell(column, index)
            )}
          </tr>
        </thead>
        <tbody>
          {renderData()}
        </tbody>
      </table>
      {/* <Pagination current={page} pages={8} onPrev={onPrevPage} onNext={onNextPage} onPage={onSetPage}/> */}
    </div>
  );
};

export default KeyTable;
