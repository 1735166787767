import React from "react";
import { observer } from "mobx-react";
import config from "./PopupsConfig";
import classnames from "classnames";
import usePopupStore from "../../hooks/usePopupStore";

import "./PopupsContainer.scss";

const PopupsContainer: React.FC<{}> = observer(() => {
  const popup = usePopupStore();

  const renderPopup = (): React.ReactNode => {
    if(!popup.type) {
      return;
    }

    const Component = config[popup.type];

    if (Component) {
      //probably in this implementation there isn't any other way beside using any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <Component {...(popup.params as any)} />;
    }
    return null;
  };

  return (
    <div
      className={classnames("popups-container", {
        ["popups-container--opened"]: popup.isVisible
      })}
      onClick={() => popup.hide()}
    >
      <div className="w-100" onClick={e => e.stopPropagation()}>
        {renderPopup()}
      </div>
    </div>
  );
});

export default PopupsContainer;
