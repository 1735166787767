import React, { useContext } from "react";
import AuthStoreContext from "../../context/AuthStoreContext";

interface TopBarProps {
  toggleSidebar: () => void;
}

const TopBar: React.FC<TopBarProps> = ({ toggleSidebar }) => {
  const auth = useContext(AuthStoreContext);

  const logout  = (): void => auth.logout();

  return (
    <div className="c-fixed-components">
      <header className="c-header c-header-light c-header-fixed c-header-with-subheader">
        <button
          type="button"
          className="c-header-toggler"
          onClick={toggleSidebar}
        >
          <i className="cil-hamburger-menu" />
        </button>
        <ul className="c-header-nav ml-auto mr-4">
          <li className="c-header-nav-item">
            {auth.isLogged && (
              <button
                type="button"
                className="btn btn-dark"
                onClick={logout}
              >
                Logout
              </button>
            )}
          </li>
        </ul>
      </header>
    </div>
  );
};

export default TopBar;
