import React from "react";
import classnames from "classnames";
import { Notification } from "../../interfaces/Notification";

import "./Toast.scss";

interface ToastProps {
  toast: Notification;
  onClose: (id: number) => void;
}

const Toast: React.FC<ToastProps> = ({ toast, onClose }) => {
  const { type, title, message, id } = toast;

  const onClick = (): void => {
    if (id) {
      onClose(id);
    }
  };

  return (
    <div
      onClick={onClick}
      className={classnames("toast", { [`toast-${type}`]: type })}
    >
      <div className="toast-title">
        {title}
        <i className="cil-x"></i>
      </div>
      {message && <div className="toast-message">{message}</div>}
    </div>
  );
};

export default Toast;
