import ky, { Options } from "ky";
import { ApiHttpError } from "../interfaces/AbstractHttpService";

import { CLIENT_ID, CLIENT_SECRET } from "appConfig";
import * as AuthApiServiceInterface from "../interfaces/AuthApiService";

export const STORE_TOKEN_KEY = "authToken";
export const REFRESH_TOKEN_KEY = "refreshToken";

class AuthApiService {
  http: typeof ky;

  constructor(options: Options) {
    this.http = ky.create(options);
  }

  onAuth(loginData: AuthApiServiceInterface.ResponseAuthData): string {
    sessionStorage.setItem(STORE_TOKEN_KEY, loginData.access_token);
    sessionStorage.setItem(REFRESH_TOKEN_KEY, loginData.refresh_token);

    return loginData.access_token;
  }

  init(): boolean {
    const token = sessionStorage.getItem(STORE_TOKEN_KEY);

    if(!token) {
      return false;
    }

    return true;
  }

  login(data: AuthApiServiceInterface.LoginParamData): Promise<string> {

    const formData = new FormData();
    formData.append("username", data.username);
    formData.append("password", data.password);
    formData.append("grant_type", "password");
    formData.append("client_id", CLIENT_ID);
    formData.append("client_secret", CLIENT_SECRET);

    return this.http.post("oauth/v2/token", { body: formData })
      .then(this.resolve)
      .then((data) => this.onAuth(data))
      .catch(this.reject);
  }

  logout(): void {
    sessionStorage.removeItem(STORE_TOKEN_KEY);
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  refreshRequest(): Promise<string | void> {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY) || "";

    const formData = new FormData();
    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", refreshToken);
    formData.append("client_id", CLIENT_ID);
    formData.append("client_secret", CLIENT_SECRET);

    return this.http.post("oauth/v2/token", { body: formData })
      .then(this.resolve)
      .then((data) => this.onAuth(data));
  }

  resolve = async (response: Response): Promise<AuthApiServiceInterface.ResponseAuthData> =>
    await response.text().then(text => (text ? JSON.parse(text) : {}));


  reject = (error: ky.HTTPError): Promise<never> => {
    // `error.response` will be undefined if server don't response
    // See: https://github.com/sindresorhus/ky/issues/107#issuecomment-471112770
    if(!error.response){
      if( process.env.NODE_ENV === "development") {
        // eslint-disable-next-line
        console.error(error);
      }

      return Promise.reject({
        statusCode: 0,
        error: "Communicate error",
        message: "Can`t communicate with server",
      });
    }

    // `error.response` is instance of Response class
    // See: https://github.com/sindresorhus/ky/issues/107#issuecomment-476048453
    return error.response.json()
      .then((err: ApiHttpError) => Promise.reject(err));
  };

}

export default AuthApiService;
